import * as React from 'react';
import { graphql } from 'gatsby';
import { DefaultLayout } from '../layouts/DefaultLayout';
import { PageTitle } from '../components/Common/PageTitle';
import NewsList from '../components/News/List';
import Awards from '../components/Common/Awards';
import { Metadata } from '@mangoart/gatsby-ui/components/Metadata';

const NewsPage = ({ data, location, locale }) => {
  const { siteMetadata } = data.site;
  const { title, icon, metadata, opengraph } = data.page;

  return (
    <DefaultLayout>
      <div className={'wrap'}>
        <PageTitle title={title} icon={icon} />
        <NewsList entries={data.news.edges} />
        <Awards />
      </div>
      <Metadata
        data={metadata}
        opengraphData={opengraph}
        defaultTitle={siteMetadata.title}
        canonicalSiteUrl={siteMetadata.canonicalSiteUrl}
        path={location.pathname}
      />
    </DefaultLayout>
  );
};

export default NewsPage;

export const NewsPageQuery = graphql`
  query News($language: String) {
    site: site {
      siteMetadata {
        title
        canonicalSiteUrl
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    page: cockpitRegionNews(lang: { eq: $language }) {
      title
      icon
      metadata {
        title
        metakeywords
        metadescription
      }
      opengraph {
        title
        description
        type
        image {
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    news: allCockpitNews(filter: { sites: { in: "ezagrar.at" }, published: { eq: true }, lang: { eq: $language } }) {
      edges {
        node {
          id
          title
          teaser
          date
          customurl
          expirationdate
          slug
          teaserimage {
            childImageSharp {
              gatsbyImageData(height: 180, aspectRatio: 2)
            }
          }
          images {
            alttext
            image {
              publicURL
              publicURL
              childImageSharp {
                gatsbyImageData(height: 180, aspectRatio: 2)
              }
            }
          }
        }
      }
    }
  }
`;
